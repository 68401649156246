import { z } from 'zod';

import { Products } from '../events';

export const GetWorkspacesForAccountRequestDtoSchema = z.object({
  product: z.nativeEnum(Products).nullish(),
});

export interface IGetWorkspacesForAccountRequestDto
  extends z.infer<typeof GetWorkspacesForAccountRequestDtoSchema> {}

export const GetWorkspaceByDomainRequestDtoSchema = z.object({
  domain: z.string(),
});

export interface IGetWorkspaceByDomainRequestDto
  extends z.infer<typeof GetWorkspaceByDomainRequestDtoSchema> {}

export const GetWorkspaceByDomainResponseDtoSchema = z.object({
  isBusinessDomainInUse: z.boolean(),
});

export interface IGetWorkspaceByDomainResponseDto
  extends z.infer<typeof GetWorkspaceByDomainResponseDtoSchema> {}
