export enum FlakePrefixes {
  Account = 'acc',
  Billing = 'bil',
  Connection = 'con',
  Flow = 'flo',
  FormItem = 'fi',
  List = 'li',
  ListRow = 'lr',
  ListProperty = 'lp',
  ListCell = 'lc',
  Node = 'nod',
  Path = 'p',
  Run = 'ru',
  TableCell = 'tc',
  TableProperty = 'tp',
  TableRow = 'tr',
  Workspace = 'ws',
}
