import { z } from 'zod';

import { SSODomainSchema } from './sso-domain.interface';

export const VerifySSODomainRequestDtoSchema = z.object({
  ssoDomain: SSODomainSchema.shape.ssoDomain,
});

export interface IVerifySSODomainRequestDto extends z.infer<typeof VerifySSODomainRequestDtoSchema> {}

export const VerifySSODomainResponseDtoSchema = z.object({
  isSSODomain: z.boolean(),
});

export const CreateSSODomainRequestDtoSchema = z.object(SSODomainSchema.shape);

export interface ICreateSSODomainRequestDto extends z.infer<typeof CreateSSODomainRequestDtoSchema> {}

export const CreateSSODomainResponseDtoSchema = z.object(SSODomainSchema.shape);

export interface ICreateSSODomainResponseDto extends z.infer<typeof CreateSSODomainResponseDtoSchema> {}
export interface IVerifySSODomainResponseDto extends z.infer<typeof VerifySSODomainResponseDtoSchema> {}
