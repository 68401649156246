import json from './config.json';

const sanitizeUrlEndSlash = (baseUrl: string) => baseUrl.replace(/\/+$/, '');

const constructFlowsiteApiUrl = (env: string, baseUrl: string) => {
  const sanitisedUrl = sanitizeUrlEndSlash(baseUrl);
  if (env !== 'local') {
    return sanitisedUrl.concat('/flowsite');
  }
  return sanitisedUrl;
};

export const environment = {
  production: json.NODE_ENV === 'production',
  environmentIdentifier: json.NODE_ENV !== 'production' ? json.NODE_ENV : '',
  fusionAuth: {
    authority: json.FUSIONAUTH_AUTHORITY,
    clientId: json.FUSIONAUTH_FLOWSITE_APPLICATION_ID,
  },
  dashpivot: {
    authority: json.FUSIONAUTH_AUTHORITY,
    clientId: json.FUSIONAUTH_DASHPIVOT_APPLICATION_ID,
    redirectPath: json.DASHPIVOT_CALLBACK_PATH,
  },
  microsoft: {
    clientId: json.MICROSOFT_CLIENT_ID,
    redirectPath: json.MICROSOFT_CALLBACK_PATH,
  },
  quickbooks: {
    clientId: json.QUICKBOOKS_CLIENT_ID,
    redirectPath: json.QUICKBOOKS_CALLBACK_PATH,
  },
  xero: {
    clientId: json.XERO_CLIENT_PKCE,
    redirectPath: json.XERO_CALLBACK_PATH,
  },
  flowsiteApiUrl: constructFlowsiteApiUrl(json.NODE_ENV, json.SITEMATE_API_URL),
  globalApiUrl: sanitizeUrlEndSlash(json.GLOBAL_API_URL),
};
