import { z } from 'zod';

import { FlakePrefixes } from '../../global-items/prefixes';
import { ZodUtility } from '../../utils';
import { AccountTypes, AccountBillingSettingsSchema } from '../billings';

export const AccountSchema = z.object({
  // This ensures that only `acc_` prefixed flake IDs are allowed.
  _id: ZodUtility.createFlakeIdSchema(FlakePrefixes.Account),
  name: z.string(),
  normalizedName: z.string().nullish(),
  legacyId: z.string().nullish(),
  region: z.string(),
  jvAccount: z.string().nullish(),
  billing: AccountBillingSettingsSchema.nullish(),
  defaultLanguage: z.string().nullish(),
  logo: z.string().nullish(),
  colorCode: z.string().nullish(),
  subdomain: z.string().nullish(),
  archived: z.boolean().nullish(),
  parentId: z.string().nullish(),

  /**
   * Indicates whether this account is managed by a person, or not.
   */
  accountType: z.nativeEnum(AccountTypes).nullish(),
  automatedBilling: z.boolean().nullish(),
  ssoEnabled: z.boolean().nullish(),
});

export interface IAccount extends z.infer<typeof AccountSchema> {}

// https://zod.dev/?id=recursive-types
export interface IAccountWithChildAccounts extends IAccount {
  childAccounts: IAccountWithChildAccounts[];
}

export const AccountWithChildAccountsSchema: z.ZodType<IAccountWithChildAccounts> = AccountSchema.extend({
  childAccounts: z.lazy(() => AccountWithChildAccountsSchema.array()),
});
