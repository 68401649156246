import { z } from 'zod';

import { AccountSchema, AccountWithChildAccountsSchema } from './account.interface';

export const GetParentAccountsResponseDtoSchema = z.object({
  parentAccounts: AccountSchema.array(),
});
export interface IGetParentAccountsResponseDto extends z.infer<typeof GetParentAccountsResponseDtoSchema> {}

export const GetChildAccountsRequestDtoSchema = z.object({
  accountId: AccountSchema.shape._id,
  depth: z.coerce.number().min(1).nullish(),
});

export const GetChildAccountsResponseDtoSchema = z.object({
  childAccounts: AccountWithChildAccountsSchema.array(),
});
export interface IGetChildAccountsResponseDto extends z.infer<typeof GetChildAccountsResponseDtoSchema> {}

export const GetPlanLimitValuesQuerySchema = z
  .object({
    includeDetails: z
      .string()
      .refine((val) => val === 'true' || val === 'false', {
        message: 'includeDetails must be a boolean',
      })
      .optional(),
    product: z.string().min(1, { message: 'product cannot be an empty string' }).optional(),
    limitType: z.string().min(1, { message: 'limitType cannot be an empty string' }).optional(),
  })
  .optional();
