import { Request, NextFunction, Response } from 'lambda-api';
import { Service } from 'typedi';

import { HttpStatusCodes } from '../models';

@Service()
export class PopulateUserIdMiddleware {
  public populateUserId(req: Request, res: Response, next: NextFunction) {
    const userId: string =
      req?.requestContext?.authorizer?.claims?.sub || req?.requestContext?.authorizer?.principalId || '';

    if (!userId || userId.length < 10) {
      res.error(HttpStatusCodes.Unauthorized, 'User Not Found');
    }
    req.userId = userId;
    next();
  }
}
