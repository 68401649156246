/*
  Inspired by simpleflakes library: https://www.npmjs.com/package/simpleflakes
  - Full rewrite, simplification
  - Custom epoch
  - Ensuring uniqueness of Flakes generated within the same millisecond
  - Using crypto package for generating random values
  - Base62 encoding that preserves lexicographical order
  - Utility methods for generating and parsing Flakes
  - Generating prefixed Flakes
*/
// @ts-expect-error: No typings for library, stable but no activity
import * as base62 from 'base62/lib/custom';
import BigNum from 'bn.js';

import { FlakePrefixes } from '../global-items/prefixes';

const base62CharsetString = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'; // Standard charset for base62 package has lowercase first. JS sorts by uppercase first, so the resulting values would not always be correctly ordered.
const base62Charset = base62.indexCharset(base62CharsetString);

export const RAW_FLAKE_LENGTH: number = 11;
// Encoding parameters
const FLAKE_EPOCH: number = 1262304000000; // Epoch ms, since Jan 1st, 2010. RUNS OUT IN 2084.
const FLAKE_EPOCH_BIGNUM: BigNum = new BigNum(FLAKE_EPOCH, 10);
const UNSIGNED_23BIT_MAX: number = 8388607;
const FLAKE_TIMESTAMP_LENGTH: number = 41;
const FLAKE_RANDOM_LENGTH: number = 23;
const FLAKE_RANDOM_SHIFT: number = 0;
const FLAKE_TIMESTAMP_SHIFT: number = 23;
const CACHE_BIT_ONES: string = '1111111111111111111111111111111111111111111111111111111111111111';

export interface IRawFlake {
  timestamp: number;
  random: string;
}

export interface IFlake extends IRawFlake {
  type: string | undefined;
  date: Date;
}

type FlakeKey = keyof typeof FlakePrefixes;

/**
 * Flake generation and parsing class. More info: https://sitemate.atlassian.net/wiki/spaces/DP/pages/1925545985/ID+format
 * @class
 */
class Flake {
  private currentTimestamp: number = 0;
  private separator = '_';
  private currentValues: string[] = [];
  public prefixes: typeof FlakePrefixes = FlakePrefixes;
  private regexMatcherString =
    '(' +
    // We capture the prefixes in a non-capturing group
    '(?:' +
    Object.values(this.prefixes).join('|') +
    ')' +
    // Add the separator
    this.separator +
    // Then add the charset as a RegEx charset
    '[' +
    base62CharsetString +
    ']' +
    // And we make sure we have RAW_FLAKE_LENGTH of them
    '{' +
    RAW_FLAKE_LENGTH +
    '})';
  public regexMatcher = new RegExp(this.regexMatcherString);

  /**
   * Generate a Flake, optionally with a type and timestamp
   * @param {string} type - The resource type. Can use Flake.prefixes or abbreviated values
   * @param {number=} timestamp - A timestamp in ms from unix epoch. Defaults to current time
   * @return {Promise<string>} A Flake as string
   */
  public async generate(
    type: FlakePrefixes | string,
    timestamp: number = new Date().getTime(),
  ): Promise<string> {
    const prefix = this.getPrefix(type);
    if (!prefix) {
      throw new Error(`Flake type '${type}' is not defined.`);
    }

    const flake = `${prefix}${this.separator}${this.generateRawFlake(timestamp)}`;
    if (!this.checkUniqueness(flake, timestamp)) {
      return this.generate(type, timestamp);
    }

    return flake;
  }

  /**
   * Parse a Flake
   * @param {string} flake - A Flake as string
   * @return {IFlake} The components of the Flake: type, timestamp, random, date
   */
  public parse(flake: string): IFlake {
    let rawFlake = flake;
    let type: string | undefined;

    // Prefix
    if (flake.includes(this.separator)) {
      const prefix = flake.split(this.separator)[0];
      rawFlake = flake.split(this.separator)[1];
      type = Object.keys(FlakePrefixes).find((key) => FlakePrefixes[key as FlakeKey] === prefix);
    }

    const parsedFlake = this.parseRawFlake(rawFlake);
    const parsedDate = new Date(parsedFlake.timestamp);

    return {
      type,
      timestamp: parsedFlake.timestamp,
      random: parsedFlake.random,
      date: parsedDate,
    };
  }

  public checkUniqueness(flake: string, timestamp: number): boolean {
    if (timestamp !== this.currentTimestamp) {
      this.currentTimestamp = timestamp;
      this.currentValues = [];
    }

    if (this.currentValues.includes(flake)) {
      return false;
    }

    this.currentValues.push(flake);
    return true;
  }

  /**
   * Validates a single Flake
   * @param {string} flake - A Flake as string
   * @returns {boolean} A value indicating whether the Flake is valid
   */
  public isValid(flake: string): boolean {
    if (!flake || typeof flake !== 'string' || !flake.includes(this.separator)) {
      return false;
    }

    const flakeParts = flake.split(this.separator);

    if (flakeParts.length > 2) {
      return false;
    }

    const [prefix, rawFlake] = flakeParts;

    try {
      const { timestamp } = this.parseRawFlake(rawFlake);

      return (
        Object.values(FlakePrefixes).includes(prefix as FlakePrefixes) &&
        rawFlake.length === RAW_FLAKE_LENGTH &&
        timestamp > FLAKE_EPOCH
      );
    } catch (error) {
      return false;
    }
  }

  /**
   * Validates a Flake path
   * @param {string} flakePath - A path consisting of multiple Flakes starting with / and separated by /
   * @returns {boolean} A value indicating whether the Flake path is valid
   */
  public isValidPath(flakePath: string): boolean {
    if (flakePath === '/') {
      return true;
    }

    const pathParts = flakePath.split('/');

    // Since we split by /, the first should always be an empty string
    if (pathParts[0] !== '') {
      return false;
    }

    if (!pathParts.slice(1).every((id) => this.isValid(id))) {
      return false;
    }

    return true;
  }

  public extractPrefix(flake: string) {
    const [prefix] = flake.split(this.separator);
    const isValidPrefix = Object.values(this.prefixes).includes(prefix as FlakePrefixes);
    return (isValidPrefix && prefix) || null;
  }

  /**
   * Gets a type prefix
   * @param {string} type - The type of the resource, either the full type from the enum, as string (case insensitive), or the abbreviation
   * @return {string} The relevant prefix for the type
   */
  private getPrefix(type: FlakePrefixes | string): string {
    const typeKey = Object.keys(FlakePrefixes).find(
      (key) => key.toLowerCase() === type.toLowerCase() || FlakePrefixes[key as FlakeKey] === type,
    );

    if (!typeKey) {
      throw new Error(`Flake type '${type}' is not defined.`);
    }

    return FlakePrefixes[typeKey as FlakeKey];
  }

  private generateRawFlake(timestamp: number = new Date().getTime()): string {
    const randomNumber = this.generateRandomNumber();

    const idAsNumber = new BigNum(timestamp - FLAKE_EPOCH, 10)
      .shln(FLAKE_TIMESTAMP_SHIFT)
      .add(new BigNum(randomNumber, 10));

    return this.encodeToBase62(idAsNumber);
  }

  private generateRandomNumber(): number {
    // Generate random value, using crypto on Node, or Math.random() on browser and react-native
    // if (
    //   typeof process === 'object' &&
    //   process.release.name === 'node' &&
    //   typeof navigator === 'object' &&
    //   navigator?.product !== 'ReactNative'
    // ) {
    //   // TODO: Temporarily disabled due to issues with react-native and crypto
    //   // eslint-disable-next-line global-require
    //   // const crypto = require('crypto');
    //   // return crypto.randomInt(0, UNSIGNED_23BIT_MAX);
    //   return Math.floor(Math.random() * UNSIGNED_23BIT_MAX);
    // }

    return Math.floor(Math.random() * UNSIGNED_23BIT_MAX);
  }

  private parseRawFlake(rawFlake: string): IRawFlake {
    const base10Decoded = this.decodeBase62(rawFlake);
    const bigInt = new BigNum(String(base10Decoded), 10);

    return {
      timestamp: this.parseTimestamp(bigInt),
      random: this.parseRandomNumber(bigInt),
    };
  }

  private parseTimestamp(idInput: BigNum): number {
    const timestamp = idInput
      .shrn(FLAKE_TIMESTAMP_SHIFT)
      .and(new BigNum(CACHE_BIT_ONES.substring(0, FLAKE_TIMESTAMP_LENGTH), 2));

    return Number(timestamp.add(FLAKE_EPOCH_BIGNUM).toString(10));
  }

  private parseRandomNumber(idInput: BigNum): string {
    const randomNumber = idInput
      .shrn(FLAKE_RANDOM_SHIFT)
      .and(new BigNum(CACHE_BIT_ONES.substring(0, FLAKE_RANDOM_LENGTH), 2));

    return randomNumber.toString(36);
  }

  private encodeToBase62(inputNumber: BigNum): string {
    return base62.encode(Number(inputNumber.toString()), base62Charset);
  }

  private decodeBase62(inputString: string): number {
    return base62.decode(inputString, base62Charset);
  }
}

export default new Flake();
