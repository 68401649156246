import { z } from 'zod';

import { WorkspaceSchema } from './workspace.interface';

const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,11}(:\d{1,5})?$/;

export const SSODomainSchema = z.object({
  ssoDomain: z.string().regex(domainRegex),
  workspaceId: WorkspaceSchema.shape._id,
  accountId: WorkspaceSchema.shape.accountId,
});

export type ISSODomain = z.infer<typeof SSODomainSchema>;
