import { z } from 'zod';

import { CompanyPlanTypes } from './company-plan-types.enum';
import { PaymentCurrencyTypes } from './payment-currency-types.enum';
import { SubscriptionTerms } from './subscription-terms.enum';
import { FlakePrefixes } from '../../global-items/prefixes';
import { ZodUtility } from '../../utils';

export const IGeneratePaymentLinkRequestDTOSchema = z.object({
  sitemateStartAccountId: ZodUtility.createFlakeIdSchema(FlakePrefixes.Account),
  paymentCurrency: z.nativeEnum(PaymentCurrencyTypes),
  dashpivot: z.object({
    planType: z.nativeEnum(CompanyPlanTypes),
    subscriptionTerm: z.enum([SubscriptionTerms.Monthly, SubscriptionTerms.OneYear]),
    contributorUsersPaid: z.number().nonnegative(),
    sitematePaidUsersPaid: z.number().nonnegative(),
  }),
  redirectSuccessUrl: z.string(),
});

export interface IGeneratePaymentLinkRequestDTO
  extends z.infer<typeof IGeneratePaymentLinkRequestDTOSchema> {}
