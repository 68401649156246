import { z } from 'zod';

import { BillingCycles } from './billing-cycles.enum';
import { CompanyPlanTypes } from './company-plan-types.enum';
import { FlowsitePlanTypes } from './flowsite-plan-types.enum';
import { GearbeltPlanType } from './gearbelt-plan-type.enum';
import { PaymentCurrencyTypes } from './payment-currency-types.enum';
import { PaymentMethods } from './payment-methods.enum';
import { PaymentTermContexts } from './payment-term-contexts.enum';
import { SubscriptionStatuses } from './subscription-statuses.enum';
import { SubscriptionTerms } from './subscription-terms.enum';

export const DashpivotBillingSettingsSchema = z.object({
  trialEndDate: z.string().datetime().nullish(),
  planType: z.nativeEnum(CompanyPlanTypes),
  subscriptionStatus: z.nativeEnum(SubscriptionStatuses).nullish(),
  initialSubscriptionDate: z.string().datetime().nullish(),
  subscriptionTerm: z.nativeEnum(SubscriptionTerms).nullish(),
  billingCycle: z.nativeEnum(BillingCycles).nullish(),
  legacyPlanRestrictionsEnabled: z.boolean().nullish(),

  /**
   * The number of `Dashpivot Contributor Users` an account has `PAID` for in their billing.
   */
  contributorUsersPaid: z.number().min(0).nullish(),
  /**
   * The number of `Sitemate Paid Users` an account has `PAID` for in their billing.
   */
  sitematePaidUsersPaid: z.number().min(0).nullish(),
  /**
   * The number of `Dashpivot Contributor Users` an account has `USED` in their billing.
   */
  contributorUsersUsed: z.number().min(0).nullish(),
  /**
   * The number of `Sitemate Paid Users` an account has `USED` in their billing.
   */
  sitematePaidUsersUsed: z.number().min(0).nullish(),
  /**
   * The number of `Dashpivot Visitor Users` an account has `USED` in their billing.
   */
  visitorUsersUsed: z.number().min(0).nullish(),
});
export interface IDashpivotBillingSettings extends z.infer<typeof DashpivotBillingSettingsSchema> {}

export const FlowsiteBillingSettingsSchema = z.object({
  trialEndDate: z.string().datetime().nullish(),
  planType: z.nativeEnum(FlowsitePlanTypes),
  subscriptionStatus: z.nativeEnum(SubscriptionStatuses).nullish(),
  initialSubscriptionDate: z.string().datetime().nullish(),
  subscriptionTerm: z.nativeEnum(SubscriptionTerms).nullish(),
  billingCycle: z.nativeEnum(BillingCycles).nullish(),
});
export interface IFlowsiteBillingSettings extends z.infer<typeof FlowsiteBillingSettingsSchema> {}

export const GearbeltBillingSettingsSchema = z.object({
  trialEndDate: z.string().datetime().nullish(),
  planType: z.nativeEnum(GearbeltPlanType),
  subscriptionStatus: z.nativeEnum(SubscriptionStatuses).nullish(),
  initialSubscriptionDate: z.string().datetime().nullish(),
  subscriptionTerm: z.nativeEnum(SubscriptionTerms).nullish(),
  billingCycle: z.nativeEnum(BillingCycles).nullish(),

  assetsFixedPaid: z.number().min(0).nullish(),
  assetsFixedUsed: z.number().min(0).nullish(),
  assetsHeavyPaid: z.number().min(0).nullish(),
  assetsHeavyUsed: z.number().min(0).nullish(),
  assetsVehiclePaid: z.number().min(0).nullish(),
  assetsVehicleUsed: z.number().min(0).nullish(),
  assetsLightPaid: z.number().min(0).nullish(),
  assetsLightUsed: z.number().min(0).nullish(),
  assetsEquipmentPaid: z.number().min(0).nullish(),
  assetsEquipmentUsed: z.number().min(0).nullish(),
  assetsToolPaid: z.number().min(0).nullish(),
  assetsToolUsed: z.number().min(0).nullish(),
});
export interface IGearbeltBillingSettings extends z.infer<typeof GearbeltBillingSettingsSchema> {}

export const AccountBillingSettingsSchema = z.object({
  billingCompanyName: z.string().nullish(),
  billingAddress: z.string().nullish(),
  billingContact: z.string().nullish(),
  billingDate: z.string().datetime().nullish(),
  initialSubscriptionDate: z.string().datetime().nullish(),
  paymentTermDay: z.number().min(0).max(60).nullish(),
  paymentTermContext: z.nativeEnum(PaymentTermContexts).nullish(),
  paymentMethod: z.nativeEnum(PaymentMethods).nullish(),
  currency: z.nativeEnum(PaymentCurrencyTypes).nullish(),

  dashpivot: DashpivotBillingSettingsSchema.nullish(),
  flowsite: FlowsiteBillingSettingsSchema.nullish(),
  gearbelt: GearbeltBillingSettingsSchema.nullish(),

  zuoraAccountId: z.string().nullish(),
});
export interface IAccountBillingSettings extends z.infer<typeof AccountBillingSettingsSchema> {}
export interface IAccountBillingSettingsWithoutProducts
  extends Omit<IAccountBillingSettings, 'dashpivot' | 'flowsite' | 'gearbelt'> {}
