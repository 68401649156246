import { z } from 'zod';

import { AccountSchema } from './account.interface';
import { FlakePrefixes } from '../../global-items/prefixes';
import { ZodUtility } from '../../utils';
import { Products } from '../events/products.enum';

export const WorkspaceSchema = z.object({
  _id: ZodUtility.createFlakeIdSchema(FlakePrefixes.Workspace),
  name: z.string(),
  normalizedName: z.string().optional(),
  accountId: AccountSchema.shape._id,
  legacyId: z.string().optional(),
  jvAccount: z.string().optional(),
  // TODO: billing property is for removal
  billing: z.any().optional(),
  defaultLanguage: z.string().optional(),
  logo: z.string().optional(),
  colorCode: z.string().optional(),
  domains: z.array(z.string()).optional(),
  jointVentureDomains: z.array(z.string()).optional(),
  archived: z.boolean().optional(),
  product: z.nativeEnum(Products),
});

// If you want to derive a TypeScript type from the Zod schema
export type IWorkspace = z.infer<typeof WorkspaceSchema>;
