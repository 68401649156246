{
  "FUSIONAUTH_AUTHORITY": "https://auth.sitemate.com",
  "NODE_ENV": "smstart",
  "GLOBAL_API_URL": "https://api-global-smstart.hseqai.com/",
  "SITEMATE_API_URL": "https://api-smstart.hseqai.com/",
  "FUSIONAUTH_FLOWSITE_APPLICATION_ID": "5514972c-b36c-4529-86ca-add214920f25",
  "FUSIONAUTH_DASHPIVOT_APPLICATION_ID": "ca01885c-af91-48ac-87d4-55b7b9187b1b",
  "DASHPIVOT_CALLBACK_PATH": "/oauth-callback/dashpivot",
  "XERO_CLIENT_PKCE": "945FE0BB63E54FCEB0BDAA8EBB5A974D",
  "XERO_CALLBACK_PATH": "/oauth-callback/xero",
  "QUICKBOOKS_CLIENT_ID": "ABo501AhhYUTvvff4DefklS5GcRmqw7lFxPq3EL4RJvWhZ1I4h",
  "QUICKBOOKS_CALLBACK_PATH": "/oauth-callback/quickbooks",
  "MICROSOFT_CLIENT_ID": "e1b77bf4-41b8-4285-a516-a3d6ba48c26f",
  "MICROSOFT_CALLBACK_PATH": "/oauth-callback/microsoft"
}
