import { z } from 'zod';

import { UserProfileSchema } from './user.interface';
import { Products } from '../events';

export const GetUserByEmailRequestDtoSchema = z.object({
  email: UserProfileSchema.shape.email,
});

export interface IGetUserByEmailRequestDto extends z.infer<typeof GetUserByEmailRequestDtoSchema> {}

export const GetUserByEmailResponseDtoSchema = z.object({
  email: UserProfileSchema.shape.email,
});

export interface IGetUserByEmailResponseDto extends z.infer<typeof GetUserByEmailResponseDtoSchema> {}

export const CreateInvitedUserRequestDtoSchema = z.object({
  email: z.string(),
  product: z.nativeEnum(Products),
  /**
   * The custom ID of the user in the product's database.
   */
  productUserId: z.string().optional(),
});
export interface ICreateInvitedUserRequestDto extends z.infer<typeof CreateInvitedUserRequestDtoSchema> {}
