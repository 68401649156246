import { z } from 'zod';

/**
 * Represents a Sitemate User Profile.
 */
export const UserProfileSchema = z.object({
  /** The unique identifier of the user profile. */
  _id: z.string(),
  /** The first name of the user. */
  firstName: z.string(),
  /** The last name of the user. */
  lastName: z.string(),
  /** The email address of the user (optional). */
  email: z.string().optional(),
  /** The phone number of the user (optional). */
  phoneNumber: z.string().optional(),
  /** The timezone of the user (optional). */
  timezone: z.string().optional(),
  /** The company name of the user (optional). */
  companyName: z.string().optional(),
  /** The short username of the user (optional). */
  shortUsername: z.string().optional(),
  /** The URL of the user's avatar image (optional). */
  avatarUrl: z.string().optional(),
  /** The URL of the user's signature image (optional). */
  signatureUrl: z.string().optional(),
  /** The date and time when the user profile was created. */
  createdAt: z.string(),
  /** The date and time when the user profile was last updated. */
  updatedAt: z.string(),
  /** The users Dashpivot User ID (optional). */
  dashpivotUserId: z.string().optional(),
  /** The users Gearbelt User ID (optional). */
  gearbeltUserId: z.string().optional(),
  /** Indicates if the user profile is archived or not (optional). */
  archived: z.boolean().optional(),
  /** Indicates if the user profile is activated or not (optional). */
  activated: z.boolean().optional(),
});

/**
 * Represents a Sitemate User Profile.
 */
export type IUserProfile = z.infer<typeof UserProfileSchema>;
