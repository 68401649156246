import { z } from 'zod';

import { AccountSchema } from './account.interface';

export const SubdomainSchema = z.object({
  subdomain: z.string(),
  region: AccountSchema.shape.region,
  accountId: AccountSchema.shape._id,
});

export type ISubdomain = z.infer<typeof SubdomainSchema>;
