import {
  DashpivotPricingPlans,
  IBasePricing,
  PaymentCurrencyTypes,
  PricingProducts,
  SitematePricingPlans,
  SubscriptionTerms,
} from '../models';

export const dashpivotPricingInputs: Record<DashpivotPricingPlans, Record<PaymentCurrencyTypes, number>> = {
  standard: {
    [PaymentCurrencyTypes.AUD]: 32,
    [PaymentCurrencyTypes.USD]: 32,
    [PaymentCurrencyTypes.CAD]: 32,
    [PaymentCurrencyTypes.EUR]: 26,
    [PaymentCurrencyTypes.GBP]: 26,
    [PaymentCurrencyTypes.ZAR]: 280,
  },
  pro: {
    [PaymentCurrencyTypes.AUD]: 54,
    [PaymentCurrencyTypes.USD]: 54,
    [PaymentCurrencyTypes.CAD]: 54,
    [PaymentCurrencyTypes.EUR]: 43,
    [PaymentCurrencyTypes.GBP]: 43,
    [PaymentCurrencyTypes.ZAR]: 432,
  },
  premium: {
    [PaymentCurrencyTypes.AUD]: 76,
    [PaymentCurrencyTypes.USD]: 76,
    [PaymentCurrencyTypes.CAD]: 76,
    [PaymentCurrencyTypes.EUR]: 60,
    [PaymentCurrencyTypes.GBP]: 60,
    [PaymentCurrencyTypes.ZAR]: 584,
  },
  platinum: {
    [PaymentCurrencyTypes.AUD]: 98,
    [PaymentCurrencyTypes.USD]: 98,
    [PaymentCurrencyTypes.CAD]: 98,
    [PaymentCurrencyTypes.EUR]: 77,
    [PaymentCurrencyTypes.GBP]: 77,
    [PaymentCurrencyTypes.ZAR]: 736,
  },
  enterprise: {
    [PaymentCurrencyTypes.AUD]: 76,
    [PaymentCurrencyTypes.USD]: 76,
    [PaymentCurrencyTypes.CAD]: 76,
    [PaymentCurrencyTypes.EUR]: 60.8,
    [PaymentCurrencyTypes.GBP]: 60.8,
    [PaymentCurrencyTypes.ZAR]: 608,
  },
};

export const sitematePricingInputs: Record<SitematePricingPlans, Record<PaymentCurrencyTypes, number>> = {
  pro: {
    [PaymentCurrencyTypes.AUD]: 15,
    [PaymentCurrencyTypes.USD]: 15,
    [PaymentCurrencyTypes.CAD]: 15,
    [PaymentCurrencyTypes.EUR]: 11,
    [PaymentCurrencyTypes.GBP]: 11,
    [PaymentCurrencyTypes.ZAR]: 120,
  },
  premium: {
    [PaymentCurrencyTypes.AUD]: 18,
    [PaymentCurrencyTypes.USD]: 18,
    [PaymentCurrencyTypes.CAD]: 18,
    [PaymentCurrencyTypes.EUR]: 13,
    [PaymentCurrencyTypes.GBP]: 13,
    [PaymentCurrencyTypes.ZAR]: 136,
  },
  platinum: {
    [PaymentCurrencyTypes.AUD]: 21,
    [PaymentCurrencyTypes.USD]: 21,
    [PaymentCurrencyTypes.CAD]: 21,
    [PaymentCurrencyTypes.EUR]: 15,
    [PaymentCurrencyTypes.GBP]: 15,
    [PaymentCurrencyTypes.ZAR]: 152,
  },
  enterprise: {
    [PaymentCurrencyTypes.AUD]: 20,
    [PaymentCurrencyTypes.USD]: 20,
    [PaymentCurrencyTypes.CAD]: 20,
    [PaymentCurrencyTypes.EUR]: 15,
    [PaymentCurrencyTypes.GBP]: 15,
    [PaymentCurrencyTypes.ZAR]: 152,
  },
};

export const basePricing: IBasePricing = {
  dashpivot: dashpivotPricingInputs,
  sitemate: sitematePricingInputs,
};

export const currencyConverterFactor: Record<PaymentCurrencyTypes, number> = {
  AUD: 1,
  USD: 1,
  CAD: 1,
  EUR: 0.8,
  GBP: 0.8,
  ZAR: 8,
};

export const termDiscounts: Record<SubscriptionTerms, number> = {
  [SubscriptionTerms.Monthly]: 0,
  [SubscriptionTerms.OneYear]: 0.1,
  [SubscriptionTerms.TwoYears]: 0.1,
  [SubscriptionTerms.ThreeYears]: 0.1,
  [SubscriptionTerms.FiveYears]: 0.1,
};

export const tierDiscounts: Record<PricingProducts, Record<number, number>> = {
  dashpivot: {
    1: 0,
    2: 2,
    3: 4,
    4: 6,
    5: 8,
    6: 10,
    7: 12,
    8: 13,
    9: 14,
    10: 15,
  },
  sitemate: {
    1: 0,
    2: 1.5,
    3: 3,
    4: 4,
    5: 4.5,
    6: 5,
    7: 5.25,
    8: 5.5,
    9: 5.75,
    10: 6,
  },
};

const tierByPaidLicenses: { range: number; tier: number }[] = [
  { range: 6, tier: 1 },
  { range: 11, tier: 2 },
  { range: 31, tier: 3 },
  { range: 51, tier: 4 },
  { range: 101, tier: 5 },
  { range: 201, tier: 6 },
  { range: 501, tier: 7 },
  { range: 1001, tier: 8 },
  { range: 2001, tier: 9 },
];

export const getTierByAmountOfPaidLicenses = (amountOfPaidLicenses: number) => {
  const lastTier = 10;
  const tierObject = tierByPaidLicenses.find((tier) => amountOfPaidLicenses < tier.range);
  return tierObject ? tierObject.tier : lastTier;
};
