import { Service } from 'typedi';

import {
  CompanyPlanTypes,
  DashpivotPricingPlans,
  IPricingInputs,
  LicenseTypes,
  PaymentCurrencyTypes,
  PricingProducts,
  SitematePricingPlans,
  SubscriptionTerms,
} from '../models';
import {
  termDiscounts,
  tierDiscounts,
  currencyConverterFactor,
  getTierByAmountOfPaidLicenses,
  basePricing,
} from '../utils/pricing-data';

@Service()
export class PricingService {
  private getTermPrice: Record<SubscriptionTerms, (inputs: IPricingInputs) => number> = {
    [SubscriptionTerms.Monthly]: (inputs) => this.getPriceByTerm(inputs, 0),
    [SubscriptionTerms.OneYear]: (inputs) => this.getPriceByTerm(inputs, 1),
    [SubscriptionTerms.TwoYears]: (inputs) => this.getPriceByTerm(inputs, 2),
    [SubscriptionTerms.ThreeYears]: (inputs) => this.getPriceByTerm(inputs, 3),
    [SubscriptionTerms.FiveYears]: (inputs) => this.getPriceByTerm(inputs, 4),
  };

  public getPricing(inputs: IPricingInputs) {
    if (inputs.amountOfPaidLicenses === 0) {
      return 0;
    }

    this.validateInputs(inputs);

    const price = this.getTermPrice[inputs.subscriptionTerm](inputs);

    return this.getConvertedPrice(price);
  }

  public validateInputs({ licenseType, amountOfPaidLicenses, planType, subscriptionTerm }: IPricingInputs) {
    const product = this.getProductByLicenseType(licenseType);

    if ([CompanyPlanTypes.Legacy, CompanyPlanTypes.FreeTrial].includes(planType)) {
      throw new Error('Legacy and Free Trial plans are not available for pricing');
    }

    if (amountOfPaidLicenses < 0) {
      throw new Error('Amount of paid licenses can not be less than 0');
    }

    if (product === PricingProducts.Sitemate && planType === CompanyPlanTypes.Standard) {
      throw new Error('Standard plan is not available for Sitemate users');
    }

    if (!subscriptionTerm) {
      throw new Error('Subscription term is required');
    }
  }

  private getProductByLicenseType(licenseType: LicenseTypes): PricingProducts {
    if ([LicenseTypes.SitemateFreeUser, LicenseTypes.SitematePaidUser].includes(licenseType)) {
      return PricingProducts.Sitemate;
    }

    return PricingProducts.Dashpivot;
  }

  private getPriceByTerm(
    inputs: IPricingInputs,
    paymentTermIndex: number,
    currentPrice: number = this.getMonthlyPrice(inputs),
    currentIndex: number = 0,
  ): number {
    if (currentIndex >= paymentTermIndex) {
      return currentPrice;
    }

    const nextPrice = currentPrice * (1 - termDiscounts[inputs.subscriptionTerm]);

    return this.getPriceByTerm(inputs, paymentTermIndex, nextPrice, currentIndex + 1);
  }

  private getMonthlyPrice({ amountOfPaidLicenses, licenseType, planType, currency }: IPricingInputs) {
    const tier = getTierByAmountOfPaidLicenses(amountOfPaidLicenses);
    const product = this.getProductByLicenseType(licenseType);
    const basePrice = this.getBasePriceByPlanType(product, planType, currency);

    return basePrice - tierDiscounts[product][tier] * currencyConverterFactor[currency];
  }

  private getBasePriceByPlanType(
    product: PricingProducts,
    planType: DashpivotPricingPlans | SitematePricingPlans,
    currency: PaymentCurrencyTypes,
  ): number {
    if (planType === CompanyPlanTypes.Standard) {
      return basePricing.dashpivot.standard[currency];
    }

    return basePricing[product][planType][currency];
  }

  private getConvertedPrice(amount: number) {
    const formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const value = new Intl.NumberFormat('en-US', formatOptions).format(amount);

    return Number(value);
  }
}
