import { z, ZodError } from 'zod';

import Flake from '../flake/flake';
import { FlakePrefixes } from '../global-items/prefixes';

export function formatError(zodError: ZodError) {
  return {
    errors: zodError.issues.map((issue) => ({
      path: issue.path,
      message: issue.message,
      code: issue.code,
    })),
  };
}

export function createFlakeIdSchema<T extends FlakePrefixes>(flakePrefix: T) {
  const type = Object.keys(FlakePrefixes).find(
    (key) => FlakePrefixes[key as keyof typeof FlakePrefixes] === flakePrefix,
  );
  // TODO: Would be nice to give it a type of `${T}_${string}` but the current codebase isn't ready.
  return z.custom<string>(
    (value) => typeof value === 'string' && Flake.isValid(value) && Flake.parse(value).type === type,
    {
      message: `Invalid ${type} Flake ID`,
    },
  );
}
