import { z } from 'zod';

import {
  AccountBillingSettingsSchema,
  DashpivotBillingSettingsSchema,
  FlowsiteBillingSettingsSchema,
  GearbeltBillingSettingsSchema,
} from './billing-settings.model';
import { FlakePrefixes } from '../../global-items/prefixes';
import { ZodUtility } from '../../utils';

// Prefer `pick` than `omit` so we're explicit about what we want to include.
export const UpdateAccountBillingSettingsRequestDtoSchema = AccountBillingSettingsSchema.pick({
  billingCompanyName: true,
  billingAddress: true,
  billingContact: true,
  billingDate: true,
  initialSubscriptionDate: true,
  paymentTermDay: true,
  paymentTermContext: true,
  paymentMethod: true,
  currency: true,
});
export interface IUpdateAccountBillingSettingsRequestDto
  extends z.infer<typeof UpdateAccountBillingSettingsRequestDtoSchema> {}

export const HandleAccountSubscriptionRequestDtoSchema = z.object({
  sitemateStartAccountId: ZodUtility.createFlakeIdSchema(FlakePrefixes.Account),
  billingSettings: z.object({
    global: AccountBillingSettingsSchema.pick({
      zuoraAccountId: true,
      billingDate: true,
      initialSubscriptionDate: true,
      paymentMethod: true,
      paymentTermDay: true,
      paymentTermContext: true,
      currency: true,
    }).nullish(),
    dashpivot: DashpivotBillingSettingsSchema.pick({
      planType: true,
      subscriptionStatus: true,
      initialSubscriptionDate: true,
      subscriptionTerm: true,
      billingCycle: true,
      contributorUsersPaid: true,
      sitematePaidUsersPaid: true,
    }).nullish(),
  }),
});
export interface IHandleAccountSubscriptionRequestDto
  extends z.infer<typeof HandleAccountSubscriptionRequestDtoSchema> {}

export const UpdateDashpivotBillingSettingsRequestDtoSchema = DashpivotBillingSettingsSchema.pick({
  trialEndDate: true,
  planType: true,
  subscriptionStatus: true,
  initialSubscriptionDate: true,
  subscriptionTerm: true,
  billingCycle: true,
  contributorUsersPaid: true,
  sitematePaidUsersPaid: true,
  legacyPlanRestrictionsEnabled: true,
});
export interface IUpdateDashpivotBillingSettingsRequestDto
  extends z.infer<typeof UpdateDashpivotBillingSettingsRequestDtoSchema> {}

export const UpdateFlowsiteBillingSettingsRequestDtoSchema = FlowsiteBillingSettingsSchema.pick({
  trialEndDate: true,
  planType: true,
  subscriptionStatus: true,
  initialSubscriptionDate: true,
  subscriptionTerm: true,
  billingCycle: true,
});
export interface IUpdateFlowsiteBillingSettingsRequestDto
  extends z.infer<typeof UpdateFlowsiteBillingSettingsRequestDtoSchema> {}

export const UpdateGearbeltBillingSettingsRequestDtoSchema = GearbeltBillingSettingsSchema.pick({
  trialEndDate: true,
  planType: true,
  subscriptionStatus: true,
  initialSubscriptionDate: true,
  subscriptionTerm: true,
  billingCycle: true,

  assetsFixedPaid: true,
  assetsFixedUsed: true,
  assetsHeavyPaid: true,
  assetsHeavyUsed: true,
  assetsVehiclePaid: true,
  assetsVehicleUsed: true,
  assetsLightPaid: true,
  assetsLightUsed: true,
  assetsEquipmentPaid: true,
  assetsEquipmentUsed: true,
  assetsToolPaid: true,
  assetsToolUsed: true,
});
export interface IUpdateGearbeltBillingSettingsRequestDto
  extends z.infer<typeof UpdateGearbeltBillingSettingsRequestDtoSchema> {}
