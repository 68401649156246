import { HttpContextToken } from '@angular/common/http';

import { Maybe } from '@site-mate/sitemate-flowsite-shared';

import { environment } from 'environments/environment';

export interface IApiConfig {
  identifier: ApiIdentifier;
  baseUrl: string;
  useAuth?: boolean;
}

export enum ApiIdentifier {
  Flowsite = '@api-fs',
  Global = '@api-global',
}

export const ApiConfigMap: Record<ApiIdentifier, IApiConfig> = {
  [ApiIdentifier.Flowsite]: {
    identifier: ApiIdentifier.Flowsite,
    baseUrl: environment.flowsiteApiUrl,
    useAuth: true,
  },
  [ApiIdentifier.Global]: {
    identifier: ApiIdentifier.Global,
    baseUrl: environment.globalApiUrl,
    useAuth: true,
  },
};

export const API_CONFIG = new HttpContextToken<Maybe<IApiConfig>>(() => undefined);
