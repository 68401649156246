export enum FlowsitePlanTypes {
  FreeTrial = 'Free Trial',
  Freemium = 'Freemium',
  Standard = 'Standard',
  Pro = 'Pro',
  Premium = 'Premium',
  Platinum = 'Platinum',
  Enterprise = 'Enterprise',
  Legacy = 'Legacy',
}
