import { z } from 'zod';

import { AccountSchema } from './account.interface';
import { WorkspaceSchema } from './workspace.interface';

const genericUserIdErrorMessage = 'Invalid User Id';

export const WorkspaceAccessSchema = z.object({
  userId: z
    .string({
      required_error: genericUserIdErrorMessage,
      invalid_type_error: genericUserIdErrorMessage,
    })
    .min(10, genericUserIdErrorMessage),
  accountId: AccountSchema.shape._id,
  workspaceId: WorkspaceSchema.shape._id,
});

export const SUPER_USER_WORKSPACE_ACCESS_ID = 'superuser';

export interface IWorkspaceAccess extends z.infer<typeof WorkspaceAccessSchema> {}
