import { z } from 'zod';

export const HTTPRequestEventSchema = z.object({
  requestDetails: z.record(z.any()),
  eventType: z.literal('request'),
  correlationId: z.string().optional(),
  headers: z.record(z.any()),
  url: z.string(),
  urlPath: z.string(),
  method: z.string(),
  params: z.record(z.any()),
  query: z.record(z.any()),
  body: z.any(),
  userId: z.string().optional(),
  resourcePath: z.string().optional(),
  timestamp: z.number(),
  service: z.string(),
  serviceDetails: z.object({
    version: z.string().optional(),
    environment: z.string(),
    region: z.string(),
  }),
});
export type IHTTPRequestEvent = z.infer<typeof HTTPRequestEventSchema>;
  
export const HTTPResponseEventSchema = z.object({
  eventType: z.literal('response'),
  correlationId: z.string().optional(),
  url: z.string(),
  urlPath: z.string(),
  method: z.string(),
  params: z.record(z.any()),
  query: z.record(z.any()),
  userId: z.string().optional(),
  resourcePath: z.string().optional(),
  timestamp: z.number(),
  service: z.string(),
  responseStatus: z.number(),
  responseTime: z.number(),
  responseSize: z.number(),
});
export type IHTTPResponseEvent = z.infer<typeof HTTPResponseEventSchema>;