// TODO: This should be renamed to `DashpivotPlanTypes` and values changed to Start Case.
// There's too many references to it atm. There's also the manual transformations.
// Should be handled on a different ticket.
export enum CompanyPlanTypes {
  FreeTrial = 'free trial',
  Legacy = 'legacy',
  Standard = 'standard',
  Pro = 'pro',
  Premium = 'premium',
  Platinum = 'platinum',
  Enterprise = 'enterprise',
}
