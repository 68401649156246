import { FlakePrefixes } from '../global-items/prefixes';

/**
 * Utility class for extracting sections of paths built as id.
 *
 * Examples of path fields for global items are accessible here:
 * https://sitemate.atlassian.net/wiki/spaces/DP/pages/1925644320/Path+field
 */

/**
 * Get the last section for a given path.
 * In case the provided path ends with a '/', this returns an empty string.
 *
 * @param path string - example: '/root/path/leaf'
 * @returns leaf string - returns 'leaf' for the example above
 */
export const getLeaf = (path: string) => path.split('/').reverse()[0];

/**
 * Get the parent section for a given path.
 * In case the provided path does not have a parent, or no '/' separator,
 * then this returns an empty string.
 *
 * @param path string - example: '/root/parent/leaf'
 * @returns leaf string - returns 'parent' for the example above
 */
export const getParent = (path: string) => (path.includes('/') ? path.split('/').reverse()[1] : '');

/**
 * Get the parent path for a given path.
 * In case the provided path does not have a parent, or no '/' separator,
 * then this returns an empty string.
 *
 * @param path string - example: '/root/parent/leaf'
 * @returns leaf string - returns '/root/parent' for the example above
 */
export const getParentPath = (path: string) =>
  path.includes('/') ? path.split('/').slice(0, -1).join('/') : '';

/**
 * Get the root section for a given path.
 * In case the provided path does not start with a '/',
 * then this returns an empty string.
 *
 * @param path string - example: '/root/parent/leaf'
 * @returns root string - returns 'root' for the example above
 */
export const getRoot = (path: string) => (path.startsWith('/') ? path.split('/')[1] : '');

/**
 * Get the full path until the provided flake prefix as the leaf.
 * Returns the falsy value if the input path is falsy,
 * and an empty string if the flake prefix is not found on the path.
 *
 * @param path string - example: '/ws_root/flo_parent/ru_leaf'
 * @param flakePrefix - example: FlakePrefixes.Flow
 * @returns root string - returns '/ws_root/flo_parent' for the example above
 */
export const getFullPathUntilFlakePrefix = (path: string, flakePrefix: FlakePrefixes) => {
  if (!path) {
    return path;
  }
  const pathFragments = path.split('/');
  const index = pathFragments.findIndex((fragment) => fragment.startsWith(flakePrefix));
  return pathFragments.slice(0, index + 1).join('/');
};
